.input-group{
    border-radius: 25px;
    margin-right: 20px; 
    background-color: rgb(206, 231, 240); 
  }
  .form-control{
    box-shadow: unset !important;
    /* background-color: rgb(186, 225, 239);  */
    outline: 0 !important;
    text-decoration: none !important; 
    /* -webkit-box-shadow: none!important;  */
    /* -moz-box-shadow: none!important; */
    
  }
  