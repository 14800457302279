.about-header{
    font-size: 2.75rem;
    font-weight: 700;
    margin-top: 50px;
    font-family: Montserrat, sans-serif !important;
    color: black;
}
  .about-radio input {
    display: none;
  }

  .about-btn {
    margin: 10px;
    width: fit-content;
    display: inline-block;
    color: black;
    text-align: center;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0px;
    padding: 10px 25px;
  }

  .about-radio input:checked + .about-btn {
    background-color: #7d47c9 ;
    color: white;
  }
  .text-fs{
    font-size: large;
    font-family: 'Open Sans',sans-serif;
    line-height: 1.8;
    padding: 30px;
  }
.full-card{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}