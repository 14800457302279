.taeb-switch {
    position: relative;
}

.taeb-switch:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 0;
    transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    background-color: #3d90ef;
    height: 100%;
    z-index: 0;
}

.taeb-switch.taeb-switch-left:after {
    left: 0;
}

.taeb-switch.taeb-switch-right:after {
    left: 50%;
}

.taeb-switch .taeb {
    display: inline-block;
    width: 50%;
    padding: 12px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    user-select: none;
    color: #3d90ef;
}

.taeb-switch .taeb.active {
    color: #ffffff;
}

.wrapper-1 {
    border-radius: 37px;
    background-color: #f4f4f4;
    padding: 8px;
    width: 100%;
    max-width: 316px;
    margin-left: auto;
    margin-right: auto;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-left: 30px;
    /* Adjust as needed to accommodate the icon */
}

.input-with-icon .search-icon {
    position: absolute;
    left: 10px;
    /* Adjust to position the icon horizontally */
    top: 50%;
    transform: translateY(-50%);
}

/* Styles for the search icon SVG (Adjust as needed) */
.input-with-icon .search-icon svg {
    width: 40px;
    height: 40px;

}

.hero-search-wrap-tag {
    background: #eae9e9;
    border-radius: 10px;
    box-shadow: 0 20px 30px rgb(0 22 84 / 15%);
    max-width: 100%;
    overflow: hidden;
    padding: 30px 40px 10px;
    position: relative;
}

.profession-post-tag {
    margin-top: 20px;
    /* Adjust as per your design */
}

.tagsborder {
    box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    -webkit-box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    -moz-box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    border-radius: 20px;
    
}
.grey-background {
    background-color: #c2bcbc1a;
    border-radius: 20px; /* Light grey color */
}

.tag-search-container {
    background: white;
    border-radius: 30px;
    display: flex;
    align-items: baseline;
}

.tag-search-container .form-control {
    background-color: white !important;
    border: none !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ffffff;
    outline: 0;
}

@media (max-width: 992px) {
    .tags-img-container {
        border-radius: 20px 0px 0px 0px !important;
    }
}

.tags-img-container {
    border-radius: 20px 0px 0px 20px;
}
.label-container {
    justify-content: space-between;
  /* flex-direction: row; */
  list-style: none;
  flex-wrap: wrap;
  color: gray;
}
.label-item {
 
    color: #808080; /* Set the text color to grey */
    font-size: 13px; /* Set the font size */
}
.info-symbol {
    font-size: 16px;
    color: black;
    margin-left: 5px; /* optional, to add some space between the text and the symbol */
}

.custom-btn {
    background-color: lightblue;
    border: none;
    color: black; /* You can customize the text color as needed */
    padding: 0.5em 1em; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
}
.highlight-text {
    color: rgb(61, 191, 243);
    font-size: 15px;
}

.circle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
    border-radius: 50%;
    background-color: grey; /* Your preferred background color */
    color: white; /* Your preferred text color */
    font-size: 16px; /* Adjust the font size as needed */
    border: none;
    cursor: pointer;
    margin-left: 5px; /* Optional, to add some space between the text and the button */
}

.card_avatar_img {
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 2px solid #ffffff;
    border-radius: 120px;
  }
  
  .upload_date {
    font-family: inherit;
    font-size: 14px;
    color: gray;
  }
  
  .page-post-image-with-border {
    border: 1px solid #bdbcbc;
    border-radius: 5px;
    margin: 4px;
    cursor: pointer;
  }
  
  .card-post {
    width: 100%;
    height: auto;
    background-color: #f5eeeee5;
    margin: 0 auto;
    border: none;
  }
  
  .shop-now-bar {
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    margin-top: 35px;
    margin-right: 140px;
    background-color: #f9f9f9;
    overflow: auto;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 10px;
    cursor: pointer;
    width: max-content;
  }
  
  .dropdown a:hover {
    background-color: #f1f1f1;
  }
  
  .show {
    display: block;
  }
  
  .location_icon_size {
    color: grey;
    margin-bottom: 4px;
    font-size: large !important;
  }
  
  .location_name_size {
    font-size: 12px;
    font-weight: 600;
  }
  
  .page-post-image-overlay {
    position: relative;
  }
  
  .page-post-lighter-overlay::before {
    content: '';
      position: absolute;
      top: 2%;
      width: 100%;
      height: auto;
      bottom: 2.5%;
      background-color: rgba(14, 13, 13, 0.37);
      border-radius: 5px;
  }
  
  .page-post-plus-symbol {
    font-size: 45px;
      position: absolute;
      color: white;
  }