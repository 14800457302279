.btn.btn-theme-light {
  background: #8dd46e;
  border: none;
  color: #030303 !important;
  padding: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: large;
}

.selected {
  background-color: #8dd46e !important;
}

.aaa {
  text-align: center;
  border-width: 0px;
  width: 120px;
  height: 70px;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 500;
}

.section {
  margin-bottom: 0px;
}

.table-container {
  margin-top: -70px;
}

.table tbody tr:last-child td {
  border-bottom-width: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.aaa.top-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 10rem;
}

.radio-buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px; 
}

.pick-plan label {
  color: #455892;
  font-family: var(--font-heading);
  font-size: 20px;
  font-weight: 600;
}

.benefit-name {
  height: 70px;
}

.tik-icon {
  color:green;}
.popup {
  color:green;
  padding: 15px;
  font-size: 20px;
  /* height: 230px; */
}
  .tick-icon {
    color: green;
    margin-right: 20px; 
    width: 100px;
}

.popup label{
  font-size: 30px;
  color: green;
}