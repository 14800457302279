.card-view {
    /* width: 250px; */
    height: auto;
    background-color: #e9e6e6;
    margin: 0 auto;
    border: none;
    overflow: inherit;
}

.card_profile_img {
    /* width: 130px;
    height: 130px; */
    border-radius: 3px;
    box-shadow: 0 0 0 5px #fff;
    margin: 0 auto;
    margin-top: -60px;
    object-fit: cover;
}

.user_details p {
    margin-bottom: 20px;
    margin-top: -5px;
}

.user_details h3 {
    margin-top: 10px;
}

.card_count {
    padding: 30px;
    border-top: 1px solid #dde1e7;
}

.count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
}

.count p {
    margin-top: -10px;
}
.green-button {
    background-color: green;
    color: white; /* Text color */
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .green-button:hover {
    background-color: darkgreen; /* Color on hover */
  }
  .about-us {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 4;
    margin-bottom: 1rem; /* Adjust as needed */
  }
  
  .about-us.expanded {
    -webkit-line-clamp: unset;
  }
  
  .read-more-btn,
  .read-less-btn {
    border: none;
    background: none;
    cursor: pointer;
    color: blue;
    margin-left: 0.5rem;
  }
  
  .read-more-btn:hover,
  .read-less-btn:hover {
    text-decoration: underline;
  }
  
  
  .hide-content {
    display: none;
  }
  
.email {
    font-size: larger;
    font-weight: 600;
    color: #7e7e7e;
}
.about {
    font-size: medium;
    font-weight: 400;
    color: #7e7e7e;
}
.name {
    font-size: 25px;
    font-weight: 600;
}

.place-marker {
    height: 20px;
    margin-right: 5px;
}

.edit_profile_icon {
    font-size: 20px;
    color: darkcyan;
}




.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}