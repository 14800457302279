.main_page {
    margin-top: 87px;
}

.round_profile_image {
    margin-top: -60px;
}

.my_professional_page_card {
    width: 320px;
    height: 450px;
    border-radius: 5px;
    box-shadow: 8px 0 12px rgba(0, 0, 0, 0.1), -8px 0 12px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.1);
}

.my_professional_page_profile_img {
    background: white;
    /* width: 100px;
    height: 100px; */
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 5px solid black;
    object-fit: cover;
}

.my_professional_page_cover_img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    background: rgb(28 107 171);
    border-radius: 5px 5px 0px 0px;
}

.my_professional_page_banner {
    width: 100%;
    height: 180px;
}

.my_professional_page_details {
    color: #6f6e74;
    font-size: 15px;
}

.my_professional_page_view {
    background: #7BE2FF;
    border: none;
    color: #15435A;
    font-weight: 600;
}

.my_professional_page_background {
    background: #F5FAFE;
}

.my_professional_page_icon {
    color: #a59f9f;
    overflow: visible;
}