.contact-us {
    padding: 80px 0 0;
}

.contact-us-container {
    width: 725px !important;
    padding: 0px 40px;
}

.submit-btn {
    background: rgba(15, 202, 152, .2) !important;
    border: 2px solid rgba(15, 202, 152, .3) !important;
    color: #0fca98 !important;
    padding: 6px 50px;
}


.form-control {
    background: aliceblue;
    border: 1px solid #edf0f9;
    height: auto;
}

.form-group {
    margin-bottom: 15px;
}

.contact-description {
    background: aliceblue;
    border: 1px solid #edf0f9;
    line-height: 1.25rem;
    width: 100%;
}

.placeholder-option {
    color: #999;
}
