._failed {
    border-bottom: solid 4px red !important;
}

._failed i {
    color: red !important;
}

._success {
    box-shadow: 0 15px 25px #00000019;
    width: 100%;
    text-align: center;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 80px;
    color: #28a745;
}

._success p {
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}

.payment-background .modal-content{
    background: transparent !important;
    border: none;
}