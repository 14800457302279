.input-group {
  border-radius: 25px;
  margin-right: 20px;
  background-color: rgb(206, 231, 240);
}

.form-control {
  box-shadow: unset !important;
  /* background-color: rgb(186, 225, 239);  */
  outline: 0 !important;
  text-decoration: none !important;
  /* -webkit-box-shadow: none!important;  */
  /* -moz-box-shadow: none!important; */

}

.all_unset {
  color: #39466d;
  display: contents;
}

.all_unset:hover {
  color: #0fca98 !important;
  display: contents;
}

.pro {
  color: #0fca98 !important;
  font-size: large;
  margin: auto;
  display: contents;

}

.signin-btn {
  background: #08184b;
  border-radius: 4px;
  width: 100px;
  font-size: large;
  color: aliceblue;
  height: 39px;
  position: relative;
  /* left: 16%; */
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #39466d;
  background-color: white;
  /* margin-left: 1rem; */
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.brand {
  margin-left: 2rem;
}

.nav-link:hover {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #0fca98;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.header {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}

.sub-menus {
  display: none;
}

.menus:hover .sub-menus {
  display: block;
  position: absolute;
  background: #f0f8ff;
  border-radius: 7px;
}

.menus:hover .sub-menus .nav-link {
  display: block;
  margin: 10px;
}

.menus:hover .sub-menus .nav-link .aaa {
  width: 150px;
  padding: 10px;
  border: 1px solid black;
  background: transparent;
  border-radius: 0;
  text-align: center;
}


.header-user-pic {
  /* width: 45px;
  height: 45px; */
  aspect-ratio: 1/1;
  border-radius: 50%;
  cursor: pointer;
}

.header-user-pic-sub-menu-wrap {
  position: absolute;
  /* top: 65%; */
  right: 7%;
  width: 320px;
}

.header-user-pic-sub-menu {
  background: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 6px 20px 0px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* margin-right: 15px; */
}

.header-user-pic-sub-menu hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: #ccc;
  margin: 15px 0 10px;
}

.sub-menu-link {
  display: flex;
  align-items: center;
  color: #525252;
  cursor: pointer;
}

.sub-menu-link p {
  width: 100%;
  margin: 0;
}

.sub-menu-link:hover p {
  font-weight: 600;
}

.sub-menu-link span {
  font-size: 22px;
  transition: transform 0.5s;
}

.sub-menu-link:hover span {
  transform: translateX(5px);
}

.header-select-profile {
  font-size: larger;
  font-weight: 600;
}

.switch-account-profile img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 15px;
}

.switch-account-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.switch-account-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.header-user-pic-border {
  display: inline-block;
  border: 4px solid rgba(200, 200, 200, 0.4);
  border-radius: 50%;
}

.header-user-pic-border:hover {
  border: 4px solid rgba(0, 0, 0, 0.2);
}

.sub-menu-link .sub-menu-link-count {
  transition: transform 0.5s;
}

.sub-menu-link:hover .sub-menu-link-count {
  transform: translateX(5px);
}