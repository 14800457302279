.card_avatar_img {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #ffffff;
  border-radius: 120px;
}

.upload_date {
  font-family: inherit;
  font-size: 14px;
  color: gray;
}

.page-post-image-with-border {
  border: 1px solid #bdbcbc;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
}

.card-post {
  width: 100%;
  height: auto;
  background-color: #f5eeeee5;
  margin: 0 auto;
  border: none;
}

.shop-now-bar {
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 35px;
  margin-right: 140px;
  background-color: #f9f9f9;
  overflow: auto;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 20px 0px 20px 20px;
  padding: 10px 10px;
  cursor: pointer;
  width: max-content;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.location_icon_size {
  color: grey;
  margin-bottom: 4px;
  font-size: large !important;
}

.location_name_size {
  font-size: 12px;
  font-weight: 600;
}

.page-post-image-overlay {
  position: relative;
}

.page-post-lighter-overlay::before {
  content: '';
  position: absolute;
  top: 2%;
  width: 100%;
  height: auto;
  bottom: 2.5%;
  background-color: rgba(14, 13, 13, 0.37);
  border-radius: 5px;
}

.page-post-plus-symbol {
  font-size: 45px;
  position: absolute;
  color: white;
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  /* border-radius: 50%; */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 1);
}

.carousel-indicators {
  z-index: 1 !important;
}

.carousel-indicators button {
  background-color: rgba(0, 0, 0, 1) !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}

.carousel-indicators button.active {
  background-color: #0072ff !important;
}


.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
  z-index: 0;
}

.foreground-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.caption {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-caption {
  display: block;
}

.read-more-caption {
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}

.read-more-caption:hover {
  text-decoration: underline;
}