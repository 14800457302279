.post-share-users {
  transition: transform 0.5s;
  box-shadow: 2px 2px 2px 2px grey;
}

.post-share-users:hover {
  transform: translateX(-10px);
}

.report-send-undo-button {
  border: none;
  cursor: pointer;
  padding: 5px;
  background: none;
  color: white;
  font-weight: 600;
  font-size: 15px;
}
.share-button {
 margin-top: -65px;
 margin-right: 40px;
}

.report-send-undo-button-color {
  border-radius: 12px;
}

.post-modal{
  max-height: 300px;
  overflow-y: scroll;
}
.share-btn-container{
  display: initial;
  float: right;
  margin-right: 30px;

}