.main-bg h1 {
    margin: 20px 0 30px 0;
    font-size: 30px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

.main-bg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 80px;
    background-color: #cde3e2;
}

.card-dev {
    height: 142px;
    width: 135px;
    position: relative;
    margin: 15px 20px;
}

.input-boxs {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5), 0px 2px 4px rgb(219 185 216 / 50%), 0px 4px 8px rgb(106 15 68 / 50%), 0px 8px 16px rgb(241 231 236 / 50%);
    cursor: pointer;
    outline: none;
}

.label-name {
    position: absolute;
    margin: 8px 20px;
    cursor: pointer;
}

h5 {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin: 15px 0 20px 0;
}

.input-boxs:hover {
    transform: scale(1.05);
}

.input-boxs:checked {
    border: 5px solid #0b9cc0;
}

.png-icon {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(1%, -30%);
}

.border-sy {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.radio-button {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .image-container {
    display: inline-block; /* Ensures the container fits the image */
  }
  
  .image-container img {
    transform: scaleX(-1); /* Mirrors the image horizontally */
  }

  
  
  
  