.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-image: url(../../../assets/bg-image.jpg);
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(93, 84, 240, .5);
  background: -webkit-linear-gradient(left, rgba(0, 168, 255, 0.5), rgba(185, 0, 255, 0.5));
  background: -o-linear-gradient(left, rgba(0, 168, 255, 0.5), rgba(185, 0, 255, 0.5));
  background: -moz-linear-gradient(left, rgba(0, 168, 255, 0.5), rgba(185, 0, 255, 0.5));
  background: linear-gradient(left, rgba(0, 168, 255, 0.5), rgba(185, 0, 255, 0.5));
  pointer-events: none;
}

.header-style {
  width: 500px;
  border-radius: 10px;
  background-color: white;
}

hr.work-hour {
  border: 10px solid green;
  border-radius: 5px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
}

.chip {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f1f1f1;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.chip:hover {
  background-color: #d9d9d9;
}

.Monday {
  background-color: #ffcccb;
}

.Tuesday {
  background-color: #ffecd3;
}

.Wednesday {
  background-color: #e6e6fa;
}

.Thursday {
  background-color: #ffefd5;
}

.Friday {
  background-color: #f0e68c;
}

.Saturday {
  background-color: #d3ffce;
}

.Sunday {
  background-color: #e0ffff;
}


/* Custom styles for the table */
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  /* Add a border-bottom to the <th> elements */
}

.custom-table td {
  padding: 10px;
}

/* Optional: Hide table outline and borders */
.custom-table {
  border: none;
  outline: none;
}

/* Optional: Add spacing between table rows */
.custom-table tbody tr {
  margin-bottom: 10px;
}

.form__input {
  color: #212529;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  background-color: rgb(255 236 211)
}

.overflow {
  overflow: overlay;
}

.selected-locations {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.location-chip {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.remove-icon {
  cursor: pointer;
  color: red;
  margin-left: 5px;
}


.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.phone-icon {
  margin-right: 8px;
  font-size: 20px;
}

.country-code-select {
  width: 120px;
  margin-right: 8px;
}

.mobile-number-field {
  flex-grow: 1;
  border: none;
  outline: none;
}

.google-business {
  width: 350px;
}

.input_time {
  width: 90px;
  height: 35px;
}

.add-times {
  cursor: pointer;
}
.custom-select {
  z-index: 9999; /* Adjust the z-index value as needed */
}

.async-typeahead-container {
  z-index: 10000; /* Adjust the z-index value as needed */
}
.add-times svg {
  visibility: visible !important;
}