.design-btn {
    visibility: hidden;
}

.box {
    -webkit-appearance: none;
    appearance: none;
    background-color: #a8dfe5;
    color: white;
    height: 50px;
    width: 145px;
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    outline: none;
}

.label-name {
    position: relative;
    cursor: pointer;
}
.dev{
    height: 100%;
}

h4 {
    font-weight: 500;
    color: rgb(61, 21, 146);
    font-size: 15px;
    padding: 5px 0;
}
/* 
.box:active {
    transform: scale(1.1);
    background-color: rgb(236, 151, 54);
} */

.box:checked {
    /* border: 3px solid rgb(52, 83, 185); */
    background-color: #0bcd85;
    color: white;
}
h4:checked {
    color: white;
}
.input-value{
    height: 3em;
    background-color:#c4d6e3;
    border-radius: 5px;
    cursor: pointer;
    display: block; 
  }
.captcha-container {
    display: flex;
    align-items: center;
  }
  
  .captcha-label {
    text-decoration: line-through;
    text-decoration-style: dashed;
    font-size: 22px;
    font-weight: inherit;
    color: #dfd7da;
    background-color: #999595;
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    /* transform: skewY(-10deg); */
  }
  
  .refresh-button {
    margin-left: 10px;
    color: #0cbbf0;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
  }
  .top{
    margin-top: 97px;
  }
   