.under-text {
    margin: 0 25px 0px 30px;
    font-family: "Product Sans", Roboto, Arial, sans-serif;
    font-size: 13px;
  }
  /* Define custom ellipsis shape for the button */
.ellipsis-button {
    width: 3.5em;
    
    height: 32px;
    border-radius: 29px;
}
.switch-account-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.switch-account-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}
.custom-card {
    background-color: #f8f9fa; /* Grey background color */
    border: 1px solid #ccc; /* Optional: Add border for better distinction */
    border-radius: 5px; /* Optional: Add border radius for rounded corners */
    padding: 10px; /* Optional: Add padding inside the cards */
}
.btn-grey {
  background-color: grey; /* Set background color to grey */
  color: white; /* Set text color to white */
  /* Add any additional styling you need */
  /* For example, padding, border, etc. */
}

  .form-check:hover {
    background: rgb(218, 217, 217);
    border-radius: 5px;
  }
  
  .report-close-button{
    border: none;
  }
  
  .report-close-button:hover{
    background: rgb(218, 217, 217);
    border-radius: 50%;
    border: none;
  }