.taeb-switch {
    position: relative;
}

.taeb-switch:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 0;
    transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    background-color: #3d90ef;
    height: 100%;
    z-index: 0;
}

.taeb-switch.taeb-switch-left:after {
    left: 0;
}

.taeb-switch.taeb-switch-right:after {
    left: 50%;
}

.taeb-switch .taeb {
    display: inline-block;
    width: 50%;
    padding: 12px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    user-select: none;
    color: #3d90ef;
}

.taeb-switch .taeb.active {
    color: #ffffff;
}

.wrapper-1 {
    border-radius: 37px;
    background-color: #f4f4f4;
    padding: 8px;
    width: 100%;
    max-width: 316px;
    margin-left: auto;
    margin-right: auto;
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-left: 30px;
    /* Adjust as needed to accommodate the icon */
}

.input-with-icon .search-icon {
    position: absolute;
    left: 10px;
    /* Adjust to position the icon horizontally */
    top: 50%;
    transform: translateY(-50%);
}

/* Styles for the search icon SVG (Adjust as needed) */
.input-with-icon .search-icon svg {
    width: 40px;
    height: 40px;

}

.hero-search-wrap-tag {
    background: #eae9e9;
    border-radius: 10px;
    box-shadow: 0 20px 30px rgb(0 22 84 / 15%);
    max-width: 100%;
    overflow: hidden;
    padding: 30px 40px 10px;
    position: relative;
}

.profession-post-tag {
    margin-top: 20px;
    /* Adjust as per your design */
}

.tagsborder {
    box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    -webkit-box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    -moz-box-shadow: 5px 8px 39px 4px rgb(116 107 107 / 75%);
    border-radius: 0; /* Removed border radius */
}


.tag-search-container {
    background: white;
    border-radius: 30px;
    display: flex;
    align-items: baseline;
    border: 1px solid grey; /* added border property */
}

.tag-search-container .form-control {
    background-color: white !important;
    border: none !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ffffff;
    outline: 0;
}

@media (max-width: 992px) {
    .tags-img-container {
        border-radius: 20px 19px 0px 0px !important;
    }

    .imagefollow{
        display: block !important;
    
    }
}
.paid-status {
    color: green;
    font-size: 12px;
}

.free-status {
    color: red;
    font-size: 12px;

}

.tags-img-container {
    border-radius: 20px 0px 0px 20px;
}
.imagefollow{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}