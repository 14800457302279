.site {
    padding: 60px;
}

.professional_page_users_card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 110px 1fr;
    grid-template-areas:
        "pc-project-1 pc-project-2 pc-project-3 pc-project-4"
        "pc-user pc-user pc-user pc-user-buttons";
    max-width: 480px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 50px rgba(63, 57, 71, .15);
}

.professional_page_users_social_icon {
    color: #a59f9f;
    overflow: visible;
}

.pc-project {
    overflow: hidden;
    position: relative;
    height: 110px;
}

.pc-project-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    transition: all .4s ease-in-out;
}

.pc-project:hover .pc-project-link {
    transform: scale(1.14);
}

.pc-project:hover .pc-project-overlay {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
}

.pc-project-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(40, 27, 64, 0.6);
    transform: translateY(-100%);
    transition: transform .3s cubic-bezier(.8, 0, .5, 1.05);
}

.pc-project-overlay-link {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: #E5DFEF;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(259, 253, 239, 0.15);
    transition: background .2s ease-in-out;
}

.pc-project-overlay-link:hover {
    background: #fff;
}

.pc-project-overlay-link svg {
    width: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.pc-user {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-area: pc-user;
}

.pc-user-image {
    padding: 18px 26px 18px 18px;
    position: relative;
    background: antiquewhite;
    border-radius: 50%;
}

.pc-user-image svg {
    border-radius: 50%;
    /* width: 100%; */
}

.pc-user-info {
    padding: 20px 20px 20px 0;
}

.pc-user-info>h3 {
    font-size: 24px;
    line-height: 1.3em;
    margin-bottom: 6px;
}

.pc-user-info>h3 a {
    color: #241943;
    text-decoration: none;
    transition: color .3s ease-in-out;
}

.pc-user-info>h3 a:hover {
    color: #432F7A;
}

.pc-user-name {
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    color: #383838;
}
.pc-user-name:hover{
    text-decoration: underline;

}
.scroll{
    height: 7.5rem;
    overflow-x: hidden !important;
    overflow: scroll;
}
.pc-user-title {
    color: rgb(161, 153, 173);
    font-size: 15px;
}

.pc-user-location {
    color: rgb(161, 153, 173);
    font-size: 15px;
}

.pc-social {
    list-style-type: none;
    display: flex;
}

.pc-social li+li {
    margin-left: 15px;
}

.pc-social li svg {
    max-width: 22px;
    position: relative;
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
}

.pc-social li a:hover svg {
    opacity: 1;
}

.pc-user-buttons {
    grid-area: pc-user-buttons;
    padding: 18px;
}

.pc-user-buttons .pc-btn {
    display: block;
    padding: 6px 20px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid #E5DFEF;
    color: #231A3B;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

.pc-user-buttons .pc-btn:hover {
    background: #E5DFEF;
}

.pc-user-buttons .pc-btn.accent {
    border-color: #4F3FF1;
    background: #4F3FF1;
    color: #fff;
    box-shadow: 0 4px 10px rgba(80, 60, 240, 0.2);
}

.pc-user-buttons .pc-btn.accent:hover {
    background: #483AD7;
}

.pc-user-buttons .pc-btn+.pc-btn {
    margin-top: 12px;
}

@media only screen and (max-width: 1024px) {

    .site {
        padding: 60px;
    }

}

@media only screen and (max-width: 767px) {

    .site {
        padding: 25px;
    }

    .professional_page_users_card {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(auto, 4);
        grid-template-areas:
            "pc-project-1 pc-project-2"
            "pc-project-3 pc-project-4"
            "pc-user pc-user"
            "pc-user-buttons pc-user-buttons"
    }

    .pc-user-image {
        padding: 18px
    }

    .pc-user-info {
        padding: 18px 18px 18px 10px;
    }

    .pc-user-buttons {
        padding: 0 18px 18px 18px;
    }
}

.professional_page_users_location_icon {
    height: 20px !important;
    width: 20px !important;
}