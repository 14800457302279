.login{
    padding: 80px 0;
  }
  .login-container {
    width: 600px !important;
    border-radius: 5px;
    position: inherit;
    padding:0px 40px;
  }
  
  .login-titles{
      color:#1c1c1c!important;
    text-align: center;
    font-family:36px Poppins, sans-serif !important;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: -1.5px;
  }
  
  .container1 h2 {
    width: 50%;
    border-bottom: 2px solid rgb(121, 116, 116);
    line-height: 0.1em;
    margin: 10px 25% 20px;
  }
  .container1 h2 span {
    background: #fff;
    padding: 0 10px;
  }
  
    .login-btn{
      background: rgba(15,202,152,.2)!important;
      border: 2px solid rgba(15,202,152,.3)!important;
      color: #0fca98!important;
      padding: 16px 50px;
    }
  
    .social-login a.btn {
      background: #3b5998;
      border-radius: 5px;
      color: #fff;
      padding: 20px 0;
      width: 100%;
  }
  /* 
  .btn{
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
  } */
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
  }
  
  .link{
    display: contents;
  }
  
  .form-control {
      background: #f6f8fd;
      border: 1px solid #edf0f9;
      box-shadow: none;
  }
  
  .input{
    background: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 6px 1px rgb(62 28 131 / 10%);
    -webkit-box-shadow: 0 0 6px 1px rgb(62 28 131 / 10%);
    height: 60px;
    overflow: hidden;
    padding-left: 45px;
  }
  
  .modal-divider:before {
    border-bottom: 1px solid #e1e5f2;
    content: " ";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .modal-divider {
    margin: 20px 0;
    position: relative;
    text-align: center;
  }
  
  .modal-divider span {
    background: #fff;
    padding: 0 20px;
    position: relative;
  }
  
  .hero-search-content.side-form label, label {
    color: #455892;
    font-family: var(--font-heading);
    font-size: 14px;
    font-weight: 600;
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
  background: aliceblue;
  }
  .input-with-icon::before {
    display: none;
  }