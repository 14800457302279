.page-header {
  margin-top: 110px;
}

.slick-arrow {
  position: absolute;
  top: 124px;
  transform: translateY(-50%);
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {

  /* height: 100%; */
  opacity: .6;
}

.slick-prev {
  z-index: 1;
}

.slick-next {
  right: 0;
}

.profile-image-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 120px;
}

.profile-image {
  width: 55px;
  height: 55px;
  object-fit: inherit;
  border-radius: 50%;
}

.font {
  font-weight: bold;
  font-size: larger;
}

.font1 {
  font-weight: medium;
  font-size: medium;
}

.card-weight {
  width: 100%;
  height: 300px;
}

.height {
  width: 300px;
  height: 1000px;
  position: relative;
  border-radius: 11px;
}

.set {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
}

.fsize {
  font-size: 80px;
  margin-bottom: -15px;
}

.pro_user_image {
  box-shadow: inset 0px 5px 30px 0px rgba(0, 0, 0, 0.5);
  height: 100%;
  object-fit: inherit;
}

.pro_user_card {
  height: 550px;
  position: relative;
}

.description-left {
  text-align: left;
}

.image-container {
  width: 200px;
  height: 100px;
}

.details-container {
  flex: 30;
  justify-content: flex-start;
}

.min-col {
  min-width: 300px;
}

.name-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  text-align: center;
  color: rgb(255 255 255);
  background-color: rgb(23 24 27);
}

.name {
  font-weight: 700;
}

.img-container {
  height: 200px;
  width: 500px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
}

.employee-description-container {
  margin-top: 0;
  width: 75%;
}

.employee-status {
  align-items: center;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.employee-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 2.5rem;
}

.linear-line {
  height: 2px;
  background-image: linear-gradient(to right, rgba(11, 110, 120, 0), rgba(60, 237, 15), rgba(197, 11, 11, 0));
  margin: 1.5rem 2rem;
}

.solid-box {
  display: inline-block;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border-width: 2px;
  border-color: rgb(0, 0, 0);
  border-radius: 9999px;
  cursor: default;
  box-sizing: border-box;
  border-style: solid;
}

.left {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.heart-icon {
  font-size: x-large;
}

.def {
  font-size: 19px;
  width: 66.6667%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}