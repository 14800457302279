.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  box-sizing: border-box;
  border-style: none !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0px !important;

}

.css-13cymwt-control:hover {
  border: none;
}

.css-t3ipsp-control:hover {
  border-color: white !important;

}

.css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  box-sizing: border-box;
  border-style: none !important;
  box-shadow: none !important;
}

.search-input-container {
  display: flex;
  align-items: center;
}

.search-icon {
  /* margin-right: 8px; */
  font-size: 20px;
  ;
}

.AsyncTypeahead {
  flex-grow: 1;
}

.divider-line {
  border-top: 1px solid #0a0a0a;
  margin-top: 10px;
}

/* Apply basic styles to the chip container */
.selected-locations {
  display: flex;
  flex-wrap: wrap;
}

/* Style for individual chips */
.location-chip {
  background-color: #f2f2f2;
  color: #333;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Add hover effect */
.location-chip:hover {
  background-color: #ddd;
  cursor: pointer;
}

.service_area_image {
  height: 80%;
  width: 80%;
}