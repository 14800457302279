.page-feed-image-overlay {
    position: relative;
}

.page-feed-lighter-overlay::before {
    content: '';
    position: absolute;
    top: 2%;
    width: 100%;
    height: auto;
    bottom: 2.5%;
    background-color: rgba(14, 13, 13, 0.37);
    border-radius: 5px;
}

.page-feed-image-with-border {
    border: 1px solid #bdbcbc;
    border-radius: 5px;
    margin: 4px;
    cursor: pointer;
}

.page-feed-plus-symbol {
    font-size: 45px;
    position: absolute;
    color: white;
}




.comment-sec {
    display: flex;
    align-items: center;
    margin: 8px 0px;
    gap: 5px;
}

.input-div {
    background: #f0f2f5;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 18px;
}

.comment-sec img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
}

.input-div input {
    background: none;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
}

.input-div textarea {
    background: none;
    width: 100%;
    border: none;
    resize: none;
}

.post-comment-content {
    display: flex;
    align-items: flex-start;
    padding: 10px;
}

.post-comment-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.post-comment-text-container {
    background-color: #d9d9d9;
    border-radius: 8px;
}

.post-comment-username {
    font-weight: bold;
    font-size: small;
}