.white {
  height: auto;
}

.red {
  height: 100px;
}

.total-color input[type="radio"] {
  display: none;
}

.total-color input[type="radio"]:checked+label {
  border-bottom: 4px solid #ff0000c7;
}

.font-color {
  color: black;
}

.send-btn {
  background-color: #0d6efd;
  color: white;
  border: none;
  height: 35px;
  border-radius: 17.5px;
}

.send-btn:hover {
  background-color: #0d6efd;
  color: white;
}

.custom-btn {
  position: fixed;
  z-index: 1;
  bottom: 10%;
  right: 10%;
  /* width: 50px;
  height: 50px; */
  border-radius: 50%;
}

.page-dropdown-content {
  display: none;
  position: absolute;
  margin-top: 35px;
  margin-right: 12px;
  background-color: #f9f9f9;
  overflow: auto;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 20px 0px 20px 20px;
  padding: 10px 10px;
  cursor: pointer;
  width: max-content;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.view-page-button {
  border: none;
  cursor: pointer;
  padding: 5px;
  background: none;
  color: white;
  font-weight: 600;
  font-size: 15px;
}

.view-page-button:hover .page-icon-rotation {
  animation: rotateAnimation 1s;
}

.page-follow-button {
  background: #586ab9;
  border-radius: 12px;
}

.page-follow-button:hover {
  color: #00e7ff;
  background: #6e84e4;
}

.page-send-message-button {
  background: #a900ff;
  border-radius: 12px;
}

.page-send-message-button:hover {
  color: #00e7ff;
  background: #6e84e4;
}

.page-send-message-button:hover .page-icon-rotation {
  animation: rotateAnimation 1s;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-follow-button:hover .page-icon-rotation {
  animation: rotateAnimation 1s;
}