.view_professional_page_single_post_background {
    background: #e9e6e6;
}

.view_professional_page_single_post_main_page {
    margin-top: 87px;
}

.single-post-image-overlay {
    position: relative;
}

.single-post-lighter-overlay::before {
    content: '';
    position: absolute;
    top: 2%;
    width: 100%;
    height: auto;
    bottom: 2.5%;
    background-color: rgba(14, 13, 13, 0.37);
    border-radius: 5px;
}

.single-post-image-with-border {
    border: 1px solid #bdbcbc;
    border-radius: 5px;
    margin: 4px;
    cursor: pointer;
}

.single-post-plus-symbol {
    font-size: 45px;
    position: absolute;
    color: white;
}

.reply-container a {
    text-decoration: none;
    color: gray;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.reply-container a:hover {
    text-decoration: underline;
    color: gray;
}

.recommend-container {
    background: white;
    border-radius: 10px;
    padding: 3px;
    font-size: 10px;
}

.post-comment-content {
    position: relative;
}

.post-comment-content:hover .more-horizicon {
    opacity: 1;
}

.more-horizicon:hover {
    background: #d9d9d9;
    border-radius: 50%;
}

.more-horizicon {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.video-play-icon {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
}

.page-name-style {
    cursor: pointer;
    text-decoration: none;
    color: #383838;
}

.page-name-style:hover {
    text-decoration: underline;
    color: black;
}