section {
    padding: 80px 0;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
  }
  
  .modal-body {
    padding: 2.5em 3em;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  /* .form-control {
    background: aliceblue;
  } */
  
  .form-select {
    background-color: aliceblue;
  }
  
  .btn.btn-theme-light-2,
  .btn.btn-theme-light-2:focus,
  .btn.btn-theme-light-2:hover {
    background: rgba(15, 202, 152, .2) !important;
    border: 2px solid rgba(15, 202, 152, .3) !important;
    color: #0fca98 !important;
    padding: 16px 50px;
    width: 100%;
  }
  
  .theme-bg {
    background: #1266e3;
  }
  
  .call-to-act {
    align-items: center;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .btn.btn-call-to-act {
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
    -webkit-box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
    color: #333640;
    font-weight: 600;
    padding: 18px 40px;
  }
  
  .call-to-act-head {
    flex: 1;
  }
  
  .call-to-act-wrap {
    padding: 50px 0;
  }
  
  
  .hero-banner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 400px;
    padding: 5em 0;
  }
  
  .image-bg,
  .image-cover {
    background-position: 50% !important;
    background-size: cover !important;
  }
  
  .hero-banner>* {
    position: relative;
    z-index: 1;
  }
  
  .hero-search-wrap {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 30px rgb(0 22 84 / 15%);
    min-width: 99%;
    overflow: hidden;
    padding: 30px 40px 40px;
    position: relative;
  }
  
  .bg-img-backround {
    background: url(https://resido.thesky9.com/storage/banners/banner-1.jpg) no-repeat;
    background-position: 50% !important;
    background-size: cover !important;
  }
  
  .btn.search-btn {
    background: #1266e3;
    border-radius: 5px;
    box-shadow: 0 5px 24px rgb(31 37 59 / 15%);
    color: #fff;
    font-size: 1.2rem;
    padding: 17px;
    width: 100%;
  }
  
  
  .text-blue {
    color: #1d96ff;
  }
  
  
  .main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .detail_features {
    /* display: flex; */
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    text-align: initial;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .detail_features label {
    color: #4d4f55;
    /* display: flex; */
    /* flex: 0 0 33.333333%; */
    font-size: 14px;
    text-align: initial;
    line-height: 1.5;
    margin-bottom: 1.2rem;
    /* width: 33.333333%; */
  }
  
  .detail_features label span {
    color: #052fc7;
    /* display: flex; */
    /* flex: 0 0 33.333333%; */
    font-size: 14px;
    text-align: initial;
    line-height: 1.5;
    margin-bottom: 1.2rem;
    /* width: 33.333333%; */
  }
  .pending {
    color: red;
  }
  .success{
    color: green;
  }
  /* Style the list of IDs */
/* Style the list of IDs */
.id-list {
  list-style-type: none; /* Remove default list styles */
  padding: 0;
}

/* Style each item in the list */
.id-button-container {
  margin: 10px 0; /* Add spacing between the buttons */
}

/* Style the button to be elliptical */
.id-button {
  background-color: #f89d77; /* Button color */
  color: red; /* Text color */
  border: none;
  border-radius: 50px; /* Make it elliptical */
  padding: 6px 30px; /* Padding to make the button longer horizontally */
  font-size: 16px;
}

.custom-card {
  border-radius: 30px; 
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.1); 
  border: none; 
  width:100%;
  background-color: white;
}



.progess{
  background-color: #D3D3D3; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 50px; /* Make it elliptical */
  padding: 10px 30px; /* Padding to make the button longer horizontally */
  font-size: 16px;
}
.download{
  background-color: #D3D3D3; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 50px; /* Make it elliptical */
  padding: 10px 30px; /* Padding to make the button longer horizontally */
  font-size: 16px;
}

.payment {
  border: 2px solid #808080; 
  border-radius: 50px;
  padding: 10px 30px; 
  font-size: 16px;        
  cursor: pointer;           
  background-color: transparent; 
  height: 50px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.paynow {
  border: none;

  border-radius: 25px;
  padding: 10px 30px; 
  font-size: 16px;        
  cursor: pointer;           
  background-color: yellow; 
  height: 50px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
}
.active-tab label {
  color: black; 
  font-weight: bold;
}

