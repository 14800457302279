.theme-bg {
  background: #1266e3;
}

.call-to-act {
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.btn.btn-call-to-act {
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
  -webkit-box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
  color: #333640;
  font-weight: 600;
  padding: 18px 40px;
}
.call-to-act-head {
  flex: 1;
}
.call-to-act-wrap {
  padding: 50px 0;
}

.dropdown{
  width: 100%;
  background: #f6f8fd;
}

.dark-footer {
  color: #858b9f;
  font-size: 14px;
  transition: all .4s ease;
  background: #252c41;
  position: relative;
  z-index: 99;
}
.footer-widget {
  padding: 20px 0;
}

.middle-icon-features-content {
  padding: 10px 30px 0;
  text-align: center;
}
img.img-footer {
  margin-bottom: 2rem;
  max-width: 180px;
}
.footer-add {
  color: #858b9f;
  font-size: 13px;
}
h4.widget-title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 26px;
}

.skin-dark-footer h4 {
  color: #fff;
}

.footer-widget ul li {
  display: block;
  list-style: none;
  margin-top: 15px;
}

.skin-dark-footer .footer-widget ul li a, .skin-dark-footer a {
  color: #858b9f;
  font-size: 14px;
  display: contents;
  transition: all .4s ease;
}

.footer-widget ul {
  margin: 0;
  padding: 0;
}

.other-store-link {
  display: inline-block;
  margin-bottom: 20px;
  width: auto;
}

.other-store-app {
  align-items: center;
  border: 1px solid #353d56;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 70px;
  padding: 0 14px;
  width: 250px;
}

.os-app-icon {
  margin-right: 13px;
}

.os-app-caps {
  font-size: 18px;
  font-weight: 600;
}

.os-app-caps span {
  color: #858b9f;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  background-clip: initial;
  border: 1px solid aliceblue;
  box-shadow: none;
  font-size: 16px;
  height: 56px;
  padding: 0.5rem 0.75rem;
}
.text-light{
  font-weight: 500;
}