.wrap-login100 {
    width: 600px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px

}

.header-inpage {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;

}

.login100-form-title {
    display: block;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 25px;
    color: #4b2354;
    line-height: 1.2;
    text-align: center;
}

.p-r-55 {
    padding-right: 55px;
}

.p-l-55 {
    padding-left: 55px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-t-80 {
    padding-top: 80px;
}

.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #fff;
    /* border-radius: 20px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.input100 {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 16px;
    color: #4b2354;
    line-height: 1.2;
    display: block;
    width: 100%;
    /* height: 62px; */
    background: 0 0;
    padding: 0 20px 0 23px;
    outline: none;
    border: none;

}

.login100-form-btn {
    padding: 0 20px;
    min-width: 100px;
    height: 50px;
    background-color: #bd59d4;
    border-radius: 25px;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 14px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    box-shadow: 0 10px 30px 0 rgba(189, 89, 212, .5);
    -moz-box-shadow: 0 10px 30px 0 rgba(189, 89, 212, .5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(189, 89, 212, .5);
    -o-box-shadow: 0 10px 30px 0 rgba(189, 89, 212, .5);
    -ms-box-shadow: 0 10px 30px 0 rgba(189, 89, 212, .5);
    outline: none !important;
    border: none;
    margin-left: 20px;
}

.line-make {
    height: 2px;
    background-color: black;
    margin: 10px;
}

.badge-style {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    background-color: #bd59d4;
    color: #ffffff;
    width: 64px;
    height: 30px;
    font-size: 15px;
}
.span-font {
    margin: 0 0px 6px 11px;
    font-family: "Product Sans", Roboto, Arial, sans-serif;
    font-size: 21px;
}
.below-text  {
    margin: 0 0px 6px 26px;
    font-family: "Product Sans", Roboto, Arial, sans-serif;
    font-size: 13px;
}
.height{
    height:68px;
}
.test{
  height: 68px;
}
.test::placeholder{
padding: 202px; 
 }