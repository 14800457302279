#veedu-app-container {
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr;
}

#veedu-sidenav {
    /* background-color: rgb(241 241 244 / 1); */
    background-image: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
    position: sticky;
    height: 100vh;
    top: 0;
    z-index: 1000;
    padding: 0.5rem;
    border-radius: 0 25px 25px 0;
    overflow: hidden auto;
}

.veedu-sidenav__list {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    padding: 0;
    margin: 0;
    gap: 2rem;
}

.veedu-sidenav__logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.veedu-sidenav__logo-text {
    height: 0;
    display: grid;
    grid-template-columns: 0fr;
    transition: 300ms ease-in-out;

    >div {
        overflow: hidden;
    }
}

.veedu-sidenav__profile {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 1rem;
    color: grey;
    padding: 0.5rem;
    cursor: pointer;
}

#veedu-sidenav:hover .veedu-sidenav__logo-text,
#veedu-sidenav:hover .veedu-sidenav__menu-link-text,
#veedu-sidenav:hover .veedu-sidenav__dropdown-menu-link-text,
#veedu-sidenav:hover .veedu-sidenav__profile-text {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
}

.veedu-sidenav__menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
}

.veedu-sidenav__menu-item {
    list-style: none;
    font-weight: bold;
    cursor: pointer;
}

.veedu-sidenav__menu-link:hover,
.veedu-sidenav__dropdown-menu-link:hover,
.veedu-sidenav__dropdown-sub-menu-link:hover,
.veedu-sidenav__profile:hover {
    background-color: #0000001a;
    border-radius: 10px;
    color: #000000b0;
}

.veedu-sidenav__menu-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    color: grey;
}

.veedu-sidenav__menu-link-text {
    font-size: 1rem;
    height: 0;
    display: grid;
    grid-template-columns: 0fr;
    transition: 300ms ease-in-out;
    white-space: nowrap;

    >div {
        overflow: hidden;
    }
}

.veedu-sidenav__profile-text {
    font-size: 1rem;
    height: 0;
    display: grid;
    grid-template-columns: 0fr;
    transition: 300ms ease-in-out;
    white-space: nowrap;

    >div {
        overflow: hidden;
    }
}


.veedu-sidenav__dropdown-menu-item {
    list-style: none;
    font-weight: bold;
    gap: 5px;
    display: flex;
    flex-flow: column;
    cursor: pointer;
}

.veedu-sidenav__dropdown-menu-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    color: grey;
}

.veedu-sidenav__dropdown-menu-link-text {
    font-size: 1rem;
    height: 0;
    display: grid;
    grid-template-columns: 0fr;
    transition: 300ms ease-in-out;
    flex-grow: 1;
    white-space: nowrap;

    >div {
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.veedu-sidenav__dropdown-sub-menu-item {
    list-style: none;
    font-weight: bold;
}

.veedu-sidenav__dropdown-sub-menu-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    padding: 0.5rem;
    color: grey;
}

.veedu-sidenav__sub-menu {
    display: grid;
    grid-template-rows: 0fr;
    transition: 300ms ease-in-out;
    overflow: hidden;
    width: 0;

    >div {
        overflow: hidden;
    }
}

.veedu-sidenav__sub-menu.veedu-sidenav__sub-menu--show {
    width: auto;
    grid-template-rows: 1fr;
}

/* .veedu-sidenav__dropdown-menu-item:hover .veedu-sidenav__sub-menu {
    width: auto;
    grid-template-rows: 1fr;
} */

.veedu-sidenav__dropdown-menu-item:hover>.veedu-sidenav__dropdown-menu-link,
.veedu-sidenav__dropdown-menu-item:hover .veedu-sidenav__sub-menu .veedu-sidenav__dropdown-sub-menu-item:hover>.veedu-sidenav__dropdown-sub-menu-link {
    background-color: #0000001a;
    border-radius: 10px;
    color: #000000b0;
}

.veedu-sidenav__dropdown-menu-item:hover>.veedu-sidenav__dropdown-menu-link,
.veedu-sidenav__sub-menu .veedu-sidenav__dropdown-sub-menu-item:hover>.veedu-sidenav__dropdown-sub-menu-link {
    background-color: #0000001a;
    border-radius: 10px;
    color: #000000b0;
}

.veedu-sidenav__dropdown-sub-menu-link-text {
    border-left: #3b82f6 5px solid;
}

@media (max-width: 768px) {

    #veedu-app-container {
        display: flex;
        flex-flow: column-reverse;
    }

    #veedu-sidenav {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-around;
        background-color: rgb(241 241 244 / 1);
        padding: 0;
        border-radius: 0;
        overflow: hidden;
        background-image: linear-gradient(0deg, #f5f7fa 0%, #c3cfe2 100%);
    }

    .veedu-sidenav__list {
        display: grid;
        grid-template-rows: none;
        grid-template-columns: 1fr auto;
        width: 100%;
        gap: 0;
        overflow: hidden;
    }

    .veedu-sidenav__logo {
        display: none;
    }

    #veedu-sidenav:hover .veedu-sidenav__logo-text,
    #veedu-sidenav:hover .veedu-sidenav__menu-link-text,
    #veedu-sidenav:hover .veedu-sidenav__dropdown-menu-link-text,
    #veedu-sidenav:hover .veedu-sidenav__profile-text {
        display: none;
    }

    .veedu-sidenav__menu {
        flex-direction: row;
        justify-content: space-between;
        overflow-x: auto;
        gap: 0;
        overflow-y: hidden;
    }

    .veedu-sidenav__menu-item {
        display: block;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        align-self: center;
    }

    .veedu-sidenav__menu-link-text {
        display: none;
    }

    .veedu-sidenav__dropdown-menu-item {
        display: block;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        align-self: center;
    }

    .veedu-sidenav__dropdown-menu-link-text {
        display: none;
    }

    .veedu-sidenav__sub-menu.veedu-sidenav__sub-menu--show {
        position: fixed;
        bottom: 55px;
        left: 0;
        padding: 0%;
        width: 100%;
        display: flex;
        justify-content: center;
        background: lightgrey;

        >div {
            overflow-x: auto;
            white-space: nowrap;
        }
    }

    .veedu-sidenav__dropdown-sub-menu-item {
        display: inline-flex;
    }

    .veedu-sidenav__dropdown-sub-menu-link-text {
        font-size: 1rem;
    }

    .veedu-sidenav__dropdown-menu-item {
        min-height: 50px;
        transition: all 300ms ease-in-out;
    }

    .veedu-sidenav__sub-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms ease-in-out;
    }

    .veedu-sidenav__sub-menu.veedu-sidenav__sub-menu--show {
        max-height: 300px;
    }

    .veedu-sidenav__profile-text {
        display: none;
    }

}