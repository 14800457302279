 .custom-input-field {
     position: relative;
 }

 .custom-input-field input {
     width: 100%;
     padding: 12px;
     border-radius: 5px;
     background: none;
     border: 3px solid #ddd;
     transition: border-color 0.3s ease;
 }

 .custom-input-field .custom-input-field-placeholder {
     position: absolute;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
     top: 50%;
     left: 12px;
     padding: 0 5px;
     line-height: 100%;
     transform: translateY(-50%);
     color: #aaa;
     transition:
         top 0.3s ease,
         color 0.3s ease,
         font-size 0.3s ease;
     pointer-events: none;
 }

 .custom-input-field input:focus {
     border-color: rgb(13, 110, 253);
     transition-delay: 0.1s
 }

 .custom-input-field input:not(:placeholder-shown):not(:focus) {
     border-color: #aaa;
     transition-delay: 0.1s
 }

 .custom-input-field:focus-within .custom-input-field-placeholder {
     top: 0;
     color: rgb(13, 110, 253);
     background: white;
     width: auto;
     opacity: 1;
 }

 .custom-input-field input:not(:placeholder-shown):not(:focus)+.custom-input-field-placeholder {
     top: 0;
     color: #aaa;
     background: white;
     width: auto;
     opacity: 1;
 }

 .custom-input-field select {
     width: 100%;
     padding: 12px;
     border-radius: 5px;
     background: none;
     border: 3px solid #ddd;
     appearance: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     transition: border-color 0.3s ease;
     background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23aaa' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.5 6.5l6 6 6-6h-12z'/%3E%3C/svg%3E");
     background-repeat: no-repeat;
     background-position: right 12px center;
     background-size: 12px 12px;
 }

 .custom-input-field select:focus {
     border-color: rgb(13, 110, 253);
     transition-delay: 0.1s;
 }

 .custom-input-field select:not(:placeholder-shown):not(:focus) {
     border-color: #aaa;
     transition-delay: 0.1s;
 }

 .custom-input-field select:focus+.custom-input-field-placeholder {
     top: 0;
     color: rgb(13, 110, 253);
     background: white;
     width: auto;
     opacity: 1;
 }

 .custom-input-field select:not(:placeholder-shown):not(:focus)+.custom-input-field-placeholder {
     top: 0;
     color: #aaa;
     background: white;
     width: auto;
     opacity: 1;
 }

 .custom-input-field .rbt-input-wrapper .rbt-input-main {
     width: 100%;
     padding: 12px;
     border-radius: 5px;
     background: none;
     border: 3px solid #ddd;
     transition: border-color 0.3s ease;
 }

 .custom-input-field .rbt-input-wrapper .rbt-input-main:focus {
     border-color: rgb(13, 110, 253);
 }

 .custom-input-field .rbt-input-wrapper .rbt-input-main:not(:placeholder-shown):not(:focus) {
     border-color: #aaa;
 }

 .custom-input-field:has(.rbt-input-wrapper .rbt-input-main:not(:placeholder-shown):not(:focus)) .custom-input-field-placeholder {
     top: 0;
     color: #aaa;
     background: white;
     width: auto;
     opacity: 1;
 }

 .custom-input-field .rbt-input-hint {
     display: none;
 }



 .custom-input-field textarea {
     width: 100%;
     padding: 12px;
     border-radius: 5px;
     background: none;
     border: 3px solid #ddd;
     transition: border-color 0.3s ease;
 }

 .custom-input-field textarea:focus {
     border-color: rgb(13, 110, 253);
     transition-delay: 0.1s;
 }

 .custom-input-field textarea:not(:placeholder-shown):not(:focus) {
     border-color: #aaa;
     transition-delay: 0.1s
 }

 .custom-input-field textarea:not(:placeholder-shown):not(:focus)+.custom-input-field-placeholder {
     top: 0;
     color: #aaa;
     background: white;
     width: auto;
     opacity: 1;
 }

 .login-page-dialog-modal .modal-content {
     border-radius: 20px;
     border: none;
 }

 .password-toggle-icon {
     position: absolute;
     right: 10px;
     top: 50%;
     transform: translateY(-50%);
     cursor: pointer;
 }