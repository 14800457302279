.custom-select {
    position: relative;
    z-index: 2;
}

.custom-typeahead {
    position: absolute;
    z-index: 1;
    top: 100%;
}

.contact_info_image {
    height: 80%;
    width: 80%;
}