.hero-banner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 400px;
    padding: 5em 0;
  }
  
  .lowercase {
    text-transform: lowercase !important;
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  
  .img-boxs {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    gap: 6px;
    align-items: center;
    width: -webkit-fill-available;
    /* margin-top: 35px; */
  }
  
  .cross-icon {
    width: 20px;
    /* Adjust the size as needed */
    height: 20px;
    /* Adjust the size as needed */
    margin-left: 5px;
    /* Adjust the spacing as needed */
  }
  
  .btnn-height-size {
    display: none;
  }
  
  .blue-text {
    color: blue;
  }
  
  .visually-hide {
    display: none;
  }
  
  .radio-btn1 {
    margin: 10px;
    width: 130px;
    height: 200px;
    border: 3px solid transparent;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px #c3c3c367;
    cursor: pointer;
  }
  
  .image-bg,
  .image-cover {
    background-position: 50% !important;
    background-size: cover !important;
  }
  
  .hero-banner>* {
    position: relative;
    z-index: 1;
  }
  
  .hero-search-wrap {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 30px rgb(0 22 84 / 15%);
    max-width: 100%;
    overflow: hidden;
    padding: 30px 40px 10px;
    position: relative;
  }
  
  .form-control {
    background-color: aliceblue;
  }
  
  .form-select {
    background-color: aliceblue;
    border: 1px solid aliceblue;
  }
  
  .form-select option {
    background-color: aliceblue;
    border: 1px solid aliceblue;
  }
  
  .blue-skin .hero-search h1 {
    color: #2d3954 !important;
  }
  
  .input-with-icon {
    position: relative;
    width: 100%;
  }
  
  .dropdown-toggle {
    width: 100%;
    justify-content: space-between;
  }
  
  .btn.search-btn {
    background: #1266e3;
    border-radius: 5px;
    box-shadow: 0 5px 24px rgb(31 37 59 / 15%);
    color: #fff;
    font-size: 1.2rem;
    padding: 17px;
    width: 100%;
  }
  
  .section1 {
    padding: 80px 0;
  }
  
  .sec-heading.center {
    text-align: center;
  }
  
  .sec-heading {
    margin-bottom: 2rem;
  }
  
  .middle-icon-features-item {
    margin-top: 30px;
  }
  
  .icon-features-wrap,
  .middle-icon-large-features-box {
    position: relative;
    text-align: center;
  }
  
  .middle-icon-features-content {
    padding: 10px 30px 0;
    text-align: center;
  }
  
  .icon-features-wrap:before {
    background: transparent;
    content: url(../../assets/arrow.png);
    height: auto;
    left: 100%;
    position: absolute;
    top: 40px;
    width: auto;
  }
  
  .middle-icon-large-features-box {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 20px;
    color: #fd5332;
    font-size: 30px;
    height: 85px;
    line-height: 90px;
    margin: 15px auto;
    transition: .25s ease-in-out;
    width: 85px;
  }
  
  .middle-icon-large-features-box.f-light-success:before {
    background: rgba(15, 202, 152, .1);
    border-color: rgba(15, 202, 152, .3);
    color: #29cf8d !important;
  }
  
  .middle-icon-large-features-box:before {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 20px;
    content: "";
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 85px;
  }
  
  .publish-btn {
    border-radius: 50px;
  }
  

.privacy {
  list-style: none; /* Removes default bullet points */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  display: flex; /* Ensures list items are in a horizontal line */
  align-items: center; /* Vertically centers items if needed */
}
/* ImageGrid.css */
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.image-grid img {
  width: 100%;
  height: auto;
}

.house-id-label {
  color: grey; /* Sets text color to grey */
  font-size: 0.9rem; /* Decreases font size */
}
.privacy li.active::after {
  content: ""; /* Adds an empty pseudo-element */
  display: block; /* Makes the pseudo-element a block-level element */
  width: 100%; /* Makes the line as wide as the li element */
  height: 2px; /* Sets the thickness of the line */
  background-color: red; /* Sets the color of the line */
  position: absolute; /* Positions the line absolutely */
  bottom: -5px; /* Positions the line below the li item */
  left: 0; /* Aligns the line with the left edge of the li item */
}
/* Style for the house-id span */
.house-id {
  color: rgba(144, 238, 167, 0.815); /* Sets text color to light blue */
  font-size: 0.9rem; /* Decreases font size to match the label */
}
/* Style for the li elements */
.privacy li {
  font-weight: bold; /* Makes text bold */
  color: black; /* Sets text color to black */
  margin-right: 1rem; /* Adds spacing between items */
  position: relative; /* Allows positioning of pseudo-element */
  cursor: pointer; /* Shows a pointer cursor on hover */
}
  .hero-search-wraps {
    background: #f3f3f3;
    border-radius: 10px;
    max-width: 100%;
  
    overflow: hidden;
    padding: 30px 40px 40px;
    position: relative;
    height: 78px;
  }
  
  .large-icon {
    font-size: 24px;
  }
  
  .hero-search-wrapss {
    background: #f3f3f3;
    border-radius: 10px;
    max-width: 365px;
    overflow: hidden;
    padding: 30px 40px 40px;
    position: relative;
    height: 65px;
  }
  
  .text-success {
    color: #0fca98 !important;
  }
  
  .middle-icon-large-features-box.f-light-warning:before {
    background: rgba(255, 143, 0, .1);
    border-color: rgba(255, 143, 0, .3);
    color: #ff8f00 !important;
  }
  
  .text-warning {
    color: #ff9b20 !important;
  }
  
  .middle-icon-features-content h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .middle-icon-features-content p {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .middle-icon-large-features-box.f-light-blue:before {
    background: rgba(29, 150, 255, .1);
    border-color: rgba(29, 150, 255, .3);
    color: #1d96ff !important;
  }
  
  .text-blue {
    color: #1d96ff;
  }
  
  option {
    font-size: 18px;
    font-weight: 300;
  
  
  }
  
  @media screen and (max-width: 600px) {
    .icon-features-wrap:before {
      display: none;
    }
  }

  
  .more {
  
    color: #20c997;
    background: #bae9f3;
    border-radius: 5px;
    font-size: 1rem;
    border: 1px solid #85b4fc;
    padding: 16px 30px;
  
  
  
  }
  
  
  /* html, body {
      max-width: 100%;
      overflow-x: hidden;
  } */
  /* test */
  .main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .radio-buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  
  .custom-radio input {
    display: none;
  }
  .title-label {
    font-size: 18px;
    white-space: nowrap; 
    color: black;
  }
  .radio-btn {
    margin: 10px;
    width: 95px;
    height: 120px;
    border: 3px solid transparent;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px #c3c3c367;
    cursor: pointer;
  }
  
  .radio-btn>i {
    color: #ffffff;
    background-color: #8373e6;
    font-size: 20px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%) scale(4);
    border-radius: 50px;
    transition: 0.2s;
    pointer-events: none;
    opacity: 0;
  }
  
  .radio-btn .hobbies-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  .radio-btn .hobbies-icon i {
    color: #455892;
    line-height: 80px;
    font-size: 50px;
  }
  
  .radio-btn .hobbies-icon h3 {
    color: #455892;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .custom-radio input:checked+.radio-btn {
    border: 3px solid #478bfb;
  }
  
  .custom-radio input:checked+.hobbies-icon>h3 {
    color: wheat;
    opacity: 1;
    transform: translateX(-50%) scale(1);
  
  }
  
  .custom-radio input:checked+.radio-btn>i {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  
  .txt {
    margin-top: 60px;
  }
  
  .mt-negative {
    margin-top: -7%;
  }
  
  .slider-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .slider-item {
    flex: 0 0 50%;
    /* Each item takes 50% width */
    padding: 10px;
    /* Adjust padding as needed */
    box-sizing: border-box;
    /* Make sure padding is included in the width */
  }
  
  .slider-item img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
  }
  
  .variable-width {
    border-radius: 10px;
  }
  
  .town-image {
    box-shadow: inset 0px 5px 30px 0px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  
  ::selection {
    color: #fff;
    background: #17a2b8;
  }
  
  .wrapper {
    width: 400px;
    background: #fff;
    border-radius: 10px;
    /* padding: 20px 25px 40px; */
    /* box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1); */
  }
  
  header h2 {
    font-size: 24px;
    font-weight: 600;
  }
  
  header p {
    margin-top: 5px;
    font-size: 16px;
  }
  
  .price-input {
    width: 100%;
    display: flex;
    margin: 10px 0 35px;
  }
  
  .price-input .field {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
  }
  
  .field input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 19px;
    margin-left: 12px;
    border-radius: 5px;
    text-align: center;
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  .price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
  }
  
  .slider {
    height: 5px;
    position: relative;
    background: #ddd;
    border-radius: 5px;
    width: 100%;
  }
  
  .slider .progress {
    height: 100%;
    position: absolute;
    border-radius: 5px;
    background: #17a2b8;
  }
  
  .range-input {
    position: relative;
  }
  
  .range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
  
  }
  
  input[type="range"]::-webkit-slider-thumb {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #17a2b8;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
  
  input[type="range"]::-moz-range-thumb {
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    background: #17a2b8;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
  
  .b-btn:hover {
    text-decoration: none;
    font-weight: bold;
  }
  
  .b-btn i {
    font-size: 20px;
    color: yellow;
    margin-top: 2rem;
  }
  
  .focus:focus {
    border: 1px solid #edf0f9;
  
  }
  
  .custom-async-typeahead .rbt-input-main {
    height: 58px;
    /* Adjust the height as needed */
  }
  
  .underline-on-hover:hover {
    text-decoration: underline;
  }
  
  
  h4 {
    font-weight: 450;
    color: #f8f9fa;
    font-size: 14px;
    padding: 5px 0;
  }
  
  
  .popular {
    font-size: 80px;
    margin-bottom: -15px;
    color: #f3f0f7
  }
  
  
  
  
  .more-dropdown {
    display: flex;
    align-items: center;
    color: #2d3954;
    position: relative;
    font-size: 18px;
    perspective: 1000px;
    z-index: 1;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  .more-dropdown_menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    perspective: 1000px;
    z-index: -1;
  
    li {
      display: none;
      color: #2d3954;
      background-color: #f2f7fc;
      width: 150px;
      padding: 10px 20px;
      font-size: 16px;
      opacity: 0;
  
      &:hover {
        background-color: #55afeb;
        color: white;
        border-radius: 5px;
      }
    }
  }
  
  
  .more-dropdown:hover .more-dropdown_menu--animated {
    display: block;
  }
  
  .more-dropdown_menu--animated {
    display: none;
  
    li {
      display: block;
      opacity: 1;
    }
  }
  
  
  .more-dropdown_menu-7 {
    animation: rotateMenu 400ms ease-in-out forwards;
    transform-origin: top center;
  }
  
  @keyframes rotateMenu {
    0% {
      transform: rotateY(-90deg);
    }
  
    70% {
      transform: rotateY(20deg);
    }
  
    100% {
      transform: rotateY(0deg);
    }
  }
  
  .blueImage {
    filter: brightness(4) saturate(100%) invert(87%) sepia(100%) saturate(400%) hue-rotate(160deg) brightness(117%) contrast(50%);
    cursor: pointer;
  }
  
  .create-new-wraps {
    background: #f3f3f3;
    border-radius: 10px;
    height: 78px;
  }
  
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-left {
    animation: slideInLeft 2s ease-out;
  }
  
  @keyframes slideInHideLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
  
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  .slide-hide-left {
    animation: slideInHideLeft 2s ease-out;
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-right {
    animation: slideInRight 2s ease-out;
  }
  
  @keyframes slideInCenter {
    from {
      transform: translateX(50%);
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-center {
    animation: slideInCenter 2s ease-out;
  }
  
  @keyframes slideInReturn {
    from {
      transform: translateX(-50%);
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-return {
    animation: slideInReturn 2s ease-out;
  }
  
  #flip {
    height: 20px;
    overflow: hidden;
  }
  
  #flip>div>div {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
    height: 45px;
    margin-bottom: 45px;
    display: inline-block;
  }
  
  #flip div:first-child {
    animation: show 10s linear infinite;
  }
  
  @keyframes show {
    0% {
      margin-top: -270px;
    }
  
    5% {
      margin-top: -180px;
    }
  
    33% {
      margin-top: -180px;
    }
  
    38% {
      margin-top: -90px;
    }
  
    66% {
      margin-top: -90px;
    }
  
    71% {
      margin-top: 0px;
    }
  
    99.99% {
      margin-top: 0px;
    }
  
    100% {
      margin-top: -270px;
    }
  }
  
  .vertical-line {
    display: inline-block;
    width: 2px; /* Width of the line */
    height: 30px; /* Customize the height as needed */
    background-color: red; /* Line color */
    cursor: pointer; /* Pointer on hover */
  }
  .hr-margin {
    margin-top: 3px; /* Adjust the margin size as needed */
  }
  /* Ensure the card image has equal height */
.equal-height-img {
  height: 200px; /* Adjust height as needed */
  object-fit: cover; /* Maintain aspect ratio */
  width: 100%; /* Ensure the image covers the full width of the card */
}
.custom-modal-header {
  background-color: #c7bbbb30; /* Light grey background color */
  border-bottom: 1px solid #dee2e6; /* Optional: border color */
}

.custom-modal-title {
  color: #000;
}
.downloading-message {
  margin-top: 10px;
  color: #007bff; /* Adjust color as needed */
  font-size: 14px;
}

custom-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.custom-icon-img {
  width: 50px;
  height: 50px;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}
.download-icon-img {
  width: 30px;
  height: 30px;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}
.custom-icon i {
  color: white; /* Color of the Font Awesome icon */
  font-size: 80px; /* Size of the icon */
}
.button-container {
  display: flex;
  justify-content: center; /* Center the button container */
  margin-top: 16px; /* Optional: Adjust margin as needed */
}

.grey-button {
  background-color: lightgrey;
  border: none;
  padding: 10px 20px; /* Add horizontal padding to increase the width */
  border-radius: 50px; /* Large value to create a pill shape */
  display: flex;
  justify-content: space-between; /* Spread the content */
  align-items: center; /* Align items vertically */
  cursor: pointer;
  width: auto; /* Let width be determined by padding */
  min-width: 200px; /* Set a minimum width to ensure it doesn't get too small */
  text-align: center; /* Center the text and icon */
}


.button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button-text {
  color: white; /* Text color */
  font-size: 16px; /* Adjust font size */
}

.button-icon {
  width: 24px; /* Adjust icon size */
  height: 24px;
}




.green-button:hover {
  background-color: #52bf52; /* Darker green on hover */
}
