.card-ui {
    width: 350px;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    border: none;
    height: 350px;
    position: relative;
  }
  .mobile-text {
    color: #989696b8;
    font-size: 15px;
  }
  .text-input {
    color: #495057;
    background-color: #fff;
    border-color: #495057;
    outline: 0;
    box-shadow: none;
    margin-right: 10px;
    height: 50px;
    max-width: 72px;
  }
  
  .cursor {
    cursor: pointer;
  }
  /* input[type=text]:focus {
    border: 2px solid red;
  } */