.hero-banner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 400px;
    padding: 5em 0;
}
.image-bg, .image-cover {
    background-position: 50%!important;
    background-size: cover!important;
}
.hero-banner>* {
    position: relative;
    z-index: 1;
}
.hero-search-wrap {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 30px rgb(0 22 84 / 15%);
    max-width: 540px;
    overflow: hidden;
    padding: 30px 40px 40px;
    position: relative;
}
.form-control {
    background-color: aliceblue;
}
.form-select {
    background-color: aliceblue;
    border: 1px solid aliceblue;
}
.form-select option {
    background-color: aliceblue;
    border: 1px solid aliceblue;
}
.bg-img-backround{
    background: url(https://resido.thesky9.com/storage/banners/banner-1.jpg) no-repeat;
    background-position: 50%!important;
    background-size: cover!important;
}
.blue-skin .hero-search h1{
    color: #2d3954!important;
}

.input-with-icon {
    position: relative;
    width: 100%;
}
.dropdown-toggle{
    width: 100%;
    justify-content: space-between;
}
.btn.search-btn {
    background: #1266e3;
    border-radius: 5px;
    box-shadow: 0 5px 24px rgb(31 37 59 / 15%);
    color: #fff;
    font-size: 1.2rem;
    padding: 17px;
    width: 100%;
}
.section1 {
    padding: 80px 0;
}
.sec-heading.center {
    text-align: center;
}
.sec-heading {
    margin-bottom: 2rem;
}
.middle-icon-features-item {
    margin-top: 30px;
}
.icon-features-wrap, .middle-icon-large-features-box {
    position: relative;
    text-align: center;
}
.middle-icon-features-content {
    padding: 10px 30px 0;
    text-align: center;
}

.icon-features-wrap:before {
    background: transparent;
    content: url(../../../assets/arrow.png);
    height: auto;
    left: 100%;
    position: absolute;
    top: 40px;
    width: auto;
}
.middle-icon-large-features-box {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 20px;
    color: #fd5332;
    font-size: 30px;
    height: 85px;
    line-height: 90px;
    margin: 15px auto;
    transition: .25s ease-in-out;
    width: 85px;
}
.middle-icon-large-features-box.f-light-success:before {
    background: rgba(15,202,152,.1);
    border-color: rgba(15,202,152,.3);
    color: #29cf8d!important;
}
.middle-icon-large-features-box:before {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 20px;
    content: "";
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 85px;
}
.text-success {
    color: #0fca98!important;
}
.middle-icon-large-features-box.f-light-warning:before {
    background: rgba(255,143,0,.1);
    border-color: rgba(255,143,0,.3);
    color: #ff8f00!important;
}
.text-warning {
    color: #ff9b20!important;
}
.middle-icon-features-content h4 {
    font-size: 20px;
    margin-bottom: 10px;
}
.middle-icon-features-content p {
    font-size: 14px;
    margin-bottom: 0;
}
.middle-icon-large-features-box.f-light-blue:before {
    background: rgba(29,150,255,.1);
    border-color: rgba(29,150,255,.3);
    color: #1d96ff!important;
}
.text-blue {
    color: #1d96ff;
}
 option{
    font-size: 18px;
    font-weight: 300;

    
}