.image-with-border {
  border: 1px solid #bdbcbc;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
}

.icon-button {
  margin-right: 10px;
  color: #000;
}

.modal-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  background-color: #f0f0f0;
}

.icon {
  margin-right: 5px;
  font-size: 20px;
}