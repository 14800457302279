.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dropdown{
  width: 100%;
  background: #f6f8fd;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.error-field { border:  1px solid red !important;}

.free {
  color: red !important;
}

.paid {
  color: green !important;
}
.input-group-text{
  background: aliceblue !important;
    border: none !important;
}
.async-container .close-icon{
  position: absolute;
  z-index: 1;
  margin-right: 8px;
}

.async-container .close-icon svg{
  border: 2px solid;
  border-radius: 50%;
  padding: 5px 6px;
  color: whitesmoke;
  background: rgb(168, 167, 167);
  cursor: pointer;
}

.async-container .close-icon svg:hover{
  background: gray;
}

.async-container input{
  padding-right: 20px;
}