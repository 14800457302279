.veedu_custom_video_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.veedu_custom_video_tag-video {
    width: 100%;
    height: 100%;
    display: block;
}

.veedu_custom_video_tag-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    background: rgba(0, 0, 0, 0.85);
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
    gap: 15px;
    z-index: 2;
}

.veedu_custom_video_wrapper:hover .veedu_custom_video_tag-controls {
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
}

.veedu_custom_video_tag-button {
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s;
}

.veedu_custom_video_tag-button:hover {
    transform: scale(1.2);
}

.veedu_custom_video_tag-seek-container {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
}

.veedu_custom_video_tag-seek {
    width: 100%;
    appearance: none;
    background: rgba(255, 255, 255, 0.2);
    height: 0.5rem;
    border-radius: 4px;
    outline: none;
    z-index: 1;
}

#veedu_custom_video_tag-progress::-webkit-slider-thumb {
    background: transparent;
    cursor: pointer;
}

.veedu_custom_video_tag-progress {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 0.5rem;
    border-radius: 4px;
    pointer-events: none;
    overflow: hidden;
    background: linear-gradient(10deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
}

.veedu_custom_video_tag-time {
    color: white;
    font-size: 1rem;
}

.veedu_custom_video_tag-volume-container {
    position: relative;
    width: 100px;
    display: flex;
    align-items: center;
}

.veedu_custom_video_tag-volume {
    width: 100%;
    appearance: none;
    background-color: rgba(255, 255, 255, 0.2);
    height: 0.5rem;
    border-radius: 3px;
    outline: none;
}

.veedu_custom_video_tag-volume-progress {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 0.5rem;
    background-color: rgba(0, 150, 255, 0.8);
    border-radius: 3px;
    pointer-events: none;
}