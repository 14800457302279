.under-text {
    margin: 0 25px 0px 30px;
    font-family: "Product Sans", Roboto, Arial, sans-serif;
    font-size: 13px;
}

.carousel-control-next,
.carousel-control-prev {

    width: 5% !important;

}

.img-box {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    margin-top: 35px;
    cursor: pointer;
}

.btn-height-size {
    height: 100px;
    width: 90%;
}

.call-to-action {
    padding: 20px !important;
}


.text-start {
    text-align: left !important;
}


.tag-container {
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 10px; */
}

.tag {
    background-color: #bbbcbd;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border: #ffffff solid 3px;
}

.tag:hover {
    border: rgb(0, 255, 34) solid 3px;
}

.tag-text {
    margin-right: 5px;
    word-break: break-word;
}

.tag-remove-btn {
    background-color: #ffffff;
    border: none;
    color: #bbbcbd;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}

.purchase-tag-card-modern {
    border-radius: 15px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    background: linear-gradient(145deg, #f0f0f0, #ffffff);
}

.purchase-tag-card-modern:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.purchase-tag-custom-checkbox {
    transform: scale(1.5);
    transition: transform 0.2s;
}

.purchase-tag-custom-checkbox:checked {
    transform: scale(1.7);
}

.purchase-tag-total-section {
    background-color: #f8f9fa;
    border-radius: 10px;
}

.purchase-tag-pay-button {
    background: linear-gradient(90deg, #007bff, #6610f2);
    border: none;
    font-size: 1.2rem;
    transition: background 0.4s ease;
}

.purchase-tag-pay-button:hover {
    background: linear-gradient(90deg, #0056b3, #520dcc);
}

.purchase-tag-cancel-button {
    background-color: #6c757d;
    border: none;
    font-size: 1.2rem;
    transition: background 0.4s ease;
}

.purchase-tag-cancel-button:hover {
    background-color: #5a6268;
}

.purchase-tag-back-icon,
.purchase-tag-close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.purchase-tag-back-icon {
    left: 20px;
    color: #6c757d;
}

.purchase-tag-close-icon {
    right: 20px;
    color: #dc3545;
}

.purchase-tag-info {
    flex-grow: 1;
    text-align: left;
}

.purchase-tag-name {
    font-weight: 600;
    font-size: 1.2rem;
}

.purchase-tag-cost-final {
    font-size: 1.3rem;
}

.purchase-tag-alert-header {
    border-bottom: none;
    background-color: #f8f9fa;
    border-radius: 15px 15px 0 0;
}

.purchase-tag-alert-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #333;
}

.purchase-tag-alert-body {
    font-size: 1.2rem;
    text-align: center;
    color: #666;
    padding: 20px 15px;
}

.purchase-tag-alert-footer {
    border-top: none;
    display: flex;
    justify-content: space-around;
    background-color: #f8f9fa;
    border-radius: 0 0 15px 15px;
    padding: 10px 15px;
}

.purchase-tag-alert-button-yes {
    background: linear-gradient(90deg, #28a745, #218838);
    color: #fff;
    border: none;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background 0.4s ease;
}

.purchase-tag-alert-button-yes:hover {
    background: linear-gradient(90deg, #218838, #1e7e34);
}

.purchase-tag-alert-button-no {
    background: linear-gradient(90deg, #dc3545, #c82333);
    color: #fff;
    border: none;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background 0.4s ease;
}

.purchase-tag-alert-button-no:hover {
    background: linear-gradient(90deg, #c82333, #bd2130);
}

.purchase-tag-add-tag-button {
    color: #007bff;
    background-color: #f8f9fa;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    padding: 8px 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 6px rgba(0, 123, 255, 0.3);
    font-weight: bold;
}

.purchase-tag-add-tag-button:hover {
    background-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
}

.purchase-tag-search-box-container {
    position: relative;
    background-color: #f8f9fa;
    border-radius: 25px;
    padding: 5px 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-right: 15px;
}

.purchase-tag-close-search-icon {
    color: #007bff;
    transition: color 0.3s ease;
}

.purchase-tag-close-search-icon:hover {
    color: #0056b3;
}

.tag-search-purchase-dialog input {
    border-radius: 25px;
}

.purchase-tag-dialog-modal .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: 0px 1px 2px 3px gray;
}

.crossed-out-cost {
    position: relative;
    display: inline-block;
    color: red;
}

.crossed-out-cost::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: red;
    top: 50%;
    left: 0;
    transform: rotate(-45deg);
    animation: fade-strike 2.5s ease-in-out infinite;
}

@keyframes fade-strike {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.purchase-tag-total-cost,
.purchase-tag-tax-cost,
.purchase-tag-grand-total-cost {
    font-size: 1.2rem;
}

.purchase-tag-tax-cost {
    color: #dc3545;
}