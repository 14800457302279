.free {
    color: red !important;
    font-size: smaller;
}

.paid {
    color: green !important;
    font-size: smaller;
}
.custom-dropdown-button{
    height: 50px;
}