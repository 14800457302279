.card-header{
    border: none;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
    z-index: 3;
    border-color: navajowhite;
    outline: 0;
    box-shadow:none;
}