@import url('https://fonts.googleapis.com/css2?family=Jost:wght@900&display=swap');

* {
    margin: 0;
    padding: 0;
}

.modal-open {
    padding-right: 0px !important;
    overflow-y: auto !important;
}

.body {
    height: 100%;
    /* background-image: url(https://d19h8kn98xvxar.cloudfront.net/images/_hero/connectwithnature.jpg);  */
    /* background-image: url(../../../assets/houses-1669667728415-1502.jpg); */
    background-size: cover;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.text-color {
    color: #3f5efb;
    font-size: 40px;
    font-family: 'Jost';
    font-weight: bold;
}

.input-box {

    width: 80%;
    height: 3em;
    background-color: #e0ecf5;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: block;

}

.input-field {

    width: 93%;
    height: 3em;
    background-color: #e0ecf5;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: block;

}

.input-box::placeholder {
    color: #4e5c79;
    padding-left: 15px;
    font-weight: 350;
    font-size: 15px;
}

/* .text-edit{
  color: #4e5c79;
  font-size: 10px;
} */

:focus {
    outline: none;
}

label {
    color: #455892;
    font-family: var(--font-heading);
    font-size: 14px;
    font-weight: 600;
}

.like_share {
    background: rgba(3, 169, 138, .1);
    border: 1px solid #03a98a;
    border-radius: 0.4rem;
    color: #03a98a !important;
    font-size: 14px;
    font-weight: 600;

}

.like_save {
    background: rgba(249, 93, 2, .1);
    border-color: #f95d02;
    color: #f95d02 !important;
    font-size: small;
}

.size {
    font-size: 12px;
}

.main-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 80px;
}

.main-page {
    width: 100%;
    margin: 0 auto;
}

.main-div h1 {
    margin: 20px 0 30px 0;
    color: gray;
    font-size: 30px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

.radio-buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.custom-radio input {
    display: none;
}

.radio-btn {
    margin: 10px;
    width: 120px;
    height: 120px;
    border: 3px solid transparent;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 20px #c3c3c367;
    cursor: pointer;
}

.radio-btn>i {
    color: #ffffff;
    background-color: #8373e6;
    /* font-size: 14px; */
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%) scale(4);
    border-radius: 50px;
    transition: 0.2s;
    pointer-events: none;
    opacity: 0;
}

.radio-btn .hobbies-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.radio-btn .hobbies-icon i {
    color: #2c2c51;
    line-height: 80px;
    font-size: 50px;
}

.radio-btn .hobbies-icon h3 {
    color: #8373e6;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.custom-radio input:checked+.radio-btn {
    border: 3px solid #478bfb;
}

.custom-radio input:checked+.hobbies-icon>h3 {
    color: wheat;
    opacity: 1;
    transform: translateX(-50%) scale(1);

}

.custom-radio input:checked+.radio-btn>i {
    opacity: 1;
    transform: translateX(-50%) scale(1);
}

.txt {
    margin-top: 60px;
}
.bgs{
    background-color:#e0ecf5;
}

/* .Connector-d1-0-2-17.active {
    border-color: rgb(255 204 0);
    border-top-width: 3px;
}
.Connector-d3-0-2-23 {
    display: block;
    border-color: rgb(189, 189, 189);
    border-top-style: solid;
    border-top-width: 3px;
} */

