.under-text {
    margin: 0 25px 0px 30px;
    font-family: "Product Sans", Roboto, Arial, sans-serif;
    font-size: 13px;
}
.carousel-control-next, .carousel-control-prev {
    
    width: 5% !important;
   
}
.img-box {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    margin-top: 35px;
    cursor: pointer;
}
/* Add these styles to your CSS */

/* Adjust the z-index values as needed */
/* Add these styles to increase the z-index of the Delete Confirmation dialog */

/* Increase the z-index values */
.react-confirm-alert-overlay {
  z-index: 10004; /* Set this value higher than the modal dialogs and previous values */
}

.react-confirm-alert-body {
  z-index: 50000; /* Set this value higher than the modal dialogs and previous values */
}

/* New styles for the Delete Confirmation dialog */
.react-confirm-alert {
  z-index: 50000; /* Set this value higher than the modal dialogs and previous values */
}

.react-confirm-alert-body {
  z-index: 50000; /* Set this value higher than the modal dialogs and previous values */
}
.confirmation-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Add this to your CSS file or style section */
.custom-confirm-dialog {
  max-width: 400px; /* Set the maximum width as per your design */
  margin: auto; /* Center the dialog horizontally */
}
.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 9999;
  width: 300px; /* Adjust the width as needed */
  max-width: 80%; /* You can use max-width for responsiveness */
  height: auto; /* Adjust the height as needed or use auto for dynamic content */
  max-height: 80vh; /* You can use max-height for responsiveness */
}

.confirmation-dialog-content {
  text-align: center;
}

.confirmation-dialog button {
  margin: 0 10px;
}
.confirmation-dialog-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-dialog-button.cancel {
  background-color: red;
}

.btn-height-size {
    height: 100px;
    width: 90%;
}

.call-to-action {
    padding: 20px !important;
}